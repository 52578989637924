/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
/* eslint-disable max-len */
/* eslint-disable n/no-process-env */
/*
 * Eventually we will do https://app.asana.com/0/1203968268804105/1204620013560511/f.
 * In the short term, we can start ensuring that `process.env` doesn't exist anywhere except
 * in this file, where default values are provided.
 */

// https://vercel.com/fortelessons/opus/settings/environment-variables
export const VERCEL_ENV = process.env.VERCEL_ENV ?? "";
export const NEXT_PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV ?? "";
export const NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ?? "";

export const MARKETING_SITE = process.env.NEXT_PUBLIC_MARKETING_SITE ?? "";
export const NEXT_PUBLIC_VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL ?? "";
// eslint-disable-next-line no-warning-comments
// TODO: Figure out how to get this error-throwing section to work for normal development but not throw error for Cypress tests:
// if (!NEXT_PUBLIC_VERCEL_URL) {
//   throw new Error("NEXT_PUBLIC_VERCEL_URL is not defined.");
// }

// https://next-auth.js.org/configuration/options#nextauth_url
export const NEXTAUTH_URL = process.env.NEXTAUTH_URL ?? "";
export const IS_LOCALHOST = NEXTAUTH_URL?.includes("localhost");
// ------------------------------------------------------------------------
// https://dashboard.stripe.com/test/apikeys
export const STRIPE_PUBLISHABLE_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? "";
export const STRIPE_SECRET_KEY =
  process.env.STRIPE_SECRET_KEY_LIVE ?? process.env.STRIPE_SECRET_KEY ?? "";
export const STRIPE_CURRENCY = "usd"; // Eventually we will want to support other currencies, but in the meantime, using this constant here is better than using "usd" strings everywhere.
// ------------------------------------------------------------------------
export const SHOULD_SEEDING_CALL_STRIPE_API_WHEN_ACCOUNTS_OBJECT_LACKS_ENTRY =
  process.env
    .SHOULD_SEEDING_CALL_STRIPE_API_WHEN_ACCOUNTS_OBJECT_LACKS_ENTRY === "true";
export const NUMBER_OF_TEACHERS_IN_SEED_DATA =
  process.env.NUMBER_OF_TEACHERS_IN_SEED_DATA ?? "1500";
// ------------------------------------------------------------------------
// https://api.slack.com/apps/A062ZLB5XPY/oauth?from=docs https://github.com/slackapi/node-slack-sdk
export const SLACK_TOKEN = process.env.SLACK_TOKEN ?? "";

/**
 * Defaults to the ID for #errors_on_production.
 */
export const SLACK_ERRORS_CHANNEL =
  process.env.SLACK_ERRORS_CHANNEL ?? "C07HUSPGWE8";
export const SLACK_DEBUG_NOTIFICATIONS_CHANNEL =
  process.env.SLACK_DEBUG_NOTIFICATIONS_CHANNEL ?? "C07HBR2FTHD";
export const SLACK_BOOKED_MEET_AND_GREETS_CHANNEL =
  process.env.SLACK_BOOKED_MEET_AND_GREETS_CHANNEL ?? "C07HSA40MS6";
// ------------------------------------------------------------------------
// https://console.cloudinary.com/pm/c-e6189d739623fdefe30e0920a521c4/developer-dashboard
export const NEXT_PUBLIC_PLACEHOLDER_TEACHER_PROFILE_VIDEO_ID =
  process.env.NEXT_PUBLIC_PLACEHOLDER_TEACHER_PROFILE_VIDEO_ID ?? "";
export const NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME =
  process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME ?? "";
// https://console.cloudinary.com/settings/c-e6189d739623fdefe30e0920a521c4/upload
export const NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET =
  process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET ?? "";
export const NEXT_PUBLIC_CLOUDINARY_UPLOAD_URL =
  process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_URL ?? "";
export const NEXT_PUBLIC_CLOUDINARY_FOLDER_IMAGES =
  process.env.NEXT_PUBLIC_CLOUDINARY_FOLDER_IMAGES ?? "userimages";
export const NEXT_PUBLIC_CLOUDINARY_FOLDER_TEACHER_PROFILE_VIDEOS =
  process.env.NEXT_PUBLIC_CLOUDINARY_FOLDER_TEACHER_PROFILE_VIDEOS ??
  "teacher_profile_videos";
export const NEXT_PUBLIC_CLOUDINARY_FOLDER_TEACHER_PROFILE_VIDEO_MAX_BYTES =
  Number(
    process.env.NEXT_PUBLIC_CLOUDINARY_FOLDER_TEACHER_PROFILE_VIDEO_MAX_BYTES ??
      500_000_000,
  ); // 500 MB

// ------------------------------------------------------------------------
export const dailyDomainName = process.env.NEXT_PUBLIC_DAILY_DOMAIN_NAME;
export const dailyApiKey = process.env.DAILY_API_KEY;
export const dailyDomainId = process.env.DAILY_DOMAIN_ID;

// When we register a webhook with Daily's POST /webhook, it will return the `.hmac` value which should be
// put into the deployed ENV. For local testing, we can POST /webhook and _supply_ the `.hmac` value in the request,
// which the response will confirm; use the hardcoded value below. Keep in mind that, as of the time of this message,
// Daily only supports one active webhook at a time -- so don't accidentally unsubscribe our Prod webhook
export const dailyWebhookHmacSecret =
  process.env.DAILY_WEBHOOK_HMAC_SECRET ||
  "ZTA0YjYyNTEtZjEyYS00YmQ3LWJjZDUtNmJiMDEwNThhYzY3"; // this default HMAC secret was randomly generated and can be used locally to

// ------------------------------------------------------------------------
export const HUBSPOT_ACCESS_TOKEN = process.env.HUBSPOT_ACCESS_TOKEN ?? "";
/**
 * Remember to keep `server/emailTemplateParameters.ts` in sync with what the template on HubSpot expects.
 * See https://lessonhomeworkspace.slack.com/archives/C03Q6PE3C2K/p1701202732366289 for the process of testing emails in staging and how to ensure smooth deployments.
 * Basically, we don't expect to *need* to define these environment variables in Vercel.
 * Reading from `proces.env` here is Plan B, for possible rare cases where we want to quickly replace a template without doing a deployment.
 * We should only ever edit a HubSpot email if the edits don't create/rename/delete custom properties.
 * Whenever we need to create/rename/delete custom properties, we should duplicate the HubSpot email template and replace the ID below with the new ID.
 */
/* eslint-disable unicorn/numeric-separators-style */
export enum HubSpotEmailId {
  // ------------------------
  // Immediate emails:
  NotifyTeacherAboutProfileApproval = Number(
    process.env.HUBSPOT_EMAIL_ID_NOTIFY_TEACHER_ABOUT_PROFILE_APPROVAL ??
      146671990602,
  ),
  StudentCancelsLessonToTeacher = Number(
    process.env.HUBSPOT_EMAIL_ID_STUDENT_CANCELS_LESSON_TO_TEACHER ??
      146687062843,
  ),
  NewTrialLessonToTeacher = Number(
    process.env.HUBSPOT_EMAIL_ID_NEW_TRIAL_LESSON_TO_TEACHER ?? 146698673581,
  ),
  MeetAndGreetConfirmation = Number(
    process.env.HUBSPOT_EMAIL_ID_MEET_AND_GREET_CONFIRMATION ?? 147788828746,
  ),
  AfterSuccessfulTrialLessonWithFutureLessonsScheduledToStudent = Number(
    process.env
      .HUBSPOT_EMAIL_ID_AFTER_SUCCESSFUL_TRIAL_LESSON_WITH_FUTURE_LESSONS_SCHEDULED_TO_STUDENT ??
      174250675605,
  ),
  AfterSuccessfulTrialLessonWithoutFutureLessonsScheduledToStudent = Number(
    process.env
      .HUBSPOT_EMAIL_ID_AFTER_SUCCESSFUL_TRIAL_LESSON_WITHOUT_FUTURE_LESSONS_SCHEDULED_TO_STUDENT ??
      174006639699,
  ),
  TeacherChangesLessonToStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_TEACHER_CHANGES_LESSON_TO_STUDENT ??
      146699341681,
  ),
  NewChatMessageFromStudentToTeacher = Number(
    process.env.HUBSPOT_EMAIL_ID_NEW_CHAT_MESSAGE_FROM_STUDENT_TO_TEACHER ??
      147778927917,
  ),
  NewChatMessageFromTeacherToStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_NEW_CHAT_MESSAGE_FROM_TEACHER_TO_STUDENT ??
      148498358446,
  ),
  AdminInviteAdmin = Number(
    process.env.HUBSPOT_EMAIL_ID_ADMIN_INVITE_ADMIN ?? 146704245911,
  ),
  AdminInviteStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_ADMIN_INVITE_STUDENT ?? 146703207262,
  ),
  MinorRequestingParent = Number(
    process.env.HUBSPOT_EMAIL_ID_MINOR_REQUESTING_PARENT ?? 146720108594,
  ),
  MinorTriedToScheduleTrialLessonToParent = Number(
    process.env
      .HUBSPOT_EMAIL_ID_MINOR_TRIED_TO_SCHEDULE_TRIAL_LESSON_TO_PARENT ??
      180517888396,
  ),
  JoinSessionToTeacher = Number(
    process.env.HUBSPOT_EMAIL_ID_JOIN_SESSION_TO_TEACHER ?? 146719945541,
  ),
  DeleteMappingTeacher = Number(
    process.env.HUBSPOT_EMAIL_ID_DELETE_MAPPING_TEACHER ?? 146744086198,
  ),
  DeleteMappingStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_DELETE_MAPPING_STUDENT ?? 146720117596,
  ),
  TeacherInviteStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_TEACHER_INVITE_STUDENT ?? 146719951521,
  ),
  SendRecordingToTeacher = Number(
    process.env.HUBSPOT_EMAIL_ID_SEND_RECORDING_TO_TEACHER ?? 146744978719,
  ),
  SendRecordingToStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_SEND_RECORDING_TO_STUDENT ?? 146720125481,
  ),
  InternalEmailToForteAdmin = Number(
    process.env.HUBSPOT_EMAIL_ID_INTERNAL_EMAIL_TO_FORTE_ADMIN ?? 147450207808,
  ),

  /* Sweetwater Buy3Get1Free Email Flow Hack {{{ */
  // sent to sweetwater students upon sign up
  SweetwaterBuy3Get1Free_Welcome = Number(
    process.env.HUBSPOT_EMAIL_ID_SWEETWATER_BUY_3_GET_1_FREE_WELCOME ??
      174573647225,
  ),
  // sent to teachers when a sweetwater student books a new TL
  SweetwaterBuy3Get1Free_NewSweetwaterStudent = Number(
    process.env
      .HUBSPOT_EMAIL_ID_SWEETWATER_BUY_3_GET_1_FREE_NEW_SWEETWATER_STUDENT ??
      174608962216,
  ),
  // sent to sweetwater students when they book a new TL
  SweetwaterBuy3Get1Free_Booked = Number(
    process.env.HUBSPOT_EMAIL_ID_SWEETWATER_BUY_3_GET_1_FREE_BOOKED ??
      174608949300,
  ),
  // sent to sweetwater students when their teacher finalizes + charges for their 1st standard lesson (2/4)
  SweetwaterBuy3Get1Free_2Of4 = Number(
    process.env.HUBSPOT_EMAIL_ID_SWEETWATER_BUY_3_GET_1_FREE_2_OF_4 ??
      174611794785,
  ),
  // sent to sweetwater students when their teacher finalizes + charges for their 2nd standard lesson (3/4)
  SweetwaterBuy3Get1Free_3Of4 = Number(
    process.env.HUBSPOT_EMAIL_ID_SWEETWATER_BUY_3_GET_1_FREE_3_OF_4 ??
      174611782917,
  ),
  // sent to sweetwater students when their teacher finalizes + charges for their 3rd standard lesson (4/4)
  SweetwaterBuy3Get1Free_4Of4 = Number(
    process.env.HUBSPOT_EMAIL_ID_SWEETWATER_BUY_3_GET_1_FREE_4_OF_4 ??
      174611803658,
  ),
  /* End Sweetwater Buy3Get1Free Email Flow Hack }}} */

  SkooveTrialLesson_NewSkooveStudent = Number(
    process.env.HUBSPOT_EMAIL_ID_SKOOVE_TRIAL_LESSON_NEW_SKOOVE_STUDENT ??
      179660361280,
  ),

  // ------------------------
  // Scheduled reminder emails:
  MeetAndGreet_Reminder_StartingSoon = Number(
    process.env.HUBSPOT_EMAIL_ID_MEET_AND_GREET_REMINDER_STARTING_SOON ??
      147995243296,
  ),
  MeetAndGreet_Reminder_GettingCloser = Number(
    process.env.HUBSPOT_EMAIL_ID_MEET_AND_GREET_REMINDER_GETTING_CLOSER ??
      147995209337,
  ),
  MeetAndGreet_Reminder_FarOut = Number(
    process.env.HUBSPOT_EMAIL_ID_MEET_AND_GREET_REMINDER_FAR_OUT ??
      147977423231,
  ),
  StandardLesson_Reminder_StartingSoon = Number(
    process.env.HUBSPOT_EMAIL_ID_STANDARD_LESSON_REMINDER_STARTING_SOON ??
      149628878341,
  ),
  StandardLesson_Reminder_GettingCloser = Number(
    process.env.HUBSPOT_EMAIL_ID_STANDARD_LESSON_REMINDER_GETTING_CLOSER ??
      149628891465,
  ),
  /**
   * @deprecated
   */
  PostLessonCheckIn = Number(
    process.env.HUBSPOT_EMAIL_ID_POST_LESSON_CHECK_IN ?? 148026630322,
  ),

  // Retool passes in the TemplateIds directly into REST, so we dont actually need these enums defined in Opus (yet).
  // The below was accurate as of 1/26/24, but commented out since we dont need it and it might have been confusing
  // /** Reengagement Emails */
  // Reengagement_1Hour_WithPreferredTeacher = 150609169706,
  // Reengagement_1Hour_General = 150617804038,
  // Reengagement_24Hour_WithPreferredTeacher = 150609169706,
  // Reengagement_24Hour_General = 150617804038,

  // /** Lesson Reminders to Students */
  // MeetAndGreetReminder_StartingSoon_Student = 147995243296,
  // MeetAndGreetReminder_GettingCloser_Student = 147995209337,
  // MeetAndGreetReminder_FarOut_Student = 147977423231,
  // StandardLessonReminder_StartingSoon_Student = 149628878341,
  // StandardLessonReminder_GettingCloser_Student = 149628891465,
  // // StandardLessonReminder_FarOut_Student= null,

  // /** Lesson Reminders to Teachers */
  // MeetAndGreetReminder_StartingSoon_Teacher = 151326100737,
  // MeetAndGreetReminder_GettingCloser_Teacher = 151324941336,
  // MeetAndGreetReminder_FarOut_Teacher = 151324955810,
  // StandardLessonReminder_StartingSoon_Teacher = 151306473045,
  // StandardLessonReminder_GettingCloser_Teacher = 151325896845,
  // // StandardLessonReminder_FarOut_Teacher= null,

  // /** Lesson Followups */
  // MeetAndGreetFollowup_Student_StudentNoShow = 150473271935,
  // MeetAndGreetFollowup_Teacher_StudentNoShow = 152378643550,
  // // StandardLessonFollowup_Student_StudentNoShow= "",
  // // StandardLessonFollowup_Teacher_StudentNoShow= "",
  // MeetAndGreetFollowup_Student_TeacherNoShow = 152369168686,
  // MeetAndGreetFollowup_Teacher_TeacherNoShow = 152378738994,
  // // StandardLessonFollowup_Student_TeacherNoShow= "",
  // // StandardLessonFollowup_Teacher_TeacherNoShow= "",
  // MeetAndGreetFollowup_Student_BothNoShow = 150473271935,
  // MeetAndGreetFollowup_Teacher_BothNoShow = 152378738994,
  // // StandardLessonFollowup_Student_BothNoShow= "",
  // // StandardLessonFollowup_Teacher_BothNoShow= "",
  // MeetAndGreetFollowup_Student_BothAttendedShortMeeting = 152370099215,
  // MeetAndGreetFollowup_Teacher_BothAttendedShortMeeting = 152378971252,
  // // StandardLessonFollowup_Student_BothAttendedShortMeeting= "",
  // // StandardLessonFollowup_Teacher_BothAttendedShortMeeting= "",
  // MeetAndGreetFollowup_Student_BothAttendedGoodMeeting = 152395078947,
  // MeetAndGreetFollowup_Teacher_BothAttendedGoodMeeting = 152384760671,
  // // StandardLessonFollowup_Student_BothAttendedGoodMeeting= "",
  // // StandardLessonFollowup_Teacher_BothAttendedGoodMeeting= "",
}
/* eslint-enable unicorn/numeric-separators-style */
// ------------------------------------------------------------------------
export const shouldSuppressEmail =
  process.env.NODE_ENV === "development" && process.env.SHOULD_SUPPRESS_EMAILS;
export const emailAddressForReceivingTestEmails =
  process.env.EMAIL_ADDRESS_FOR_RECEIVING_TEST_EMAILS ?? "";

/**
 * TypeScript doesn't seem to support computed string enums, so we're using "as const" here and then exporting the type separately below.
 * https://stackoverflow.com/a/64740836/
 */
export const forteAdminEmailAddress = {
  BILLING:
    // We'll set the production value to be "billing@fortelessons.com", but we want to be sure NEVER to set any other environment to send to that address.
    process.env.FORTE_ADMIN_EMAIL_ADDRESS_BILLING ?? "",
  EMAIL_SEND_TRACKING:
    // We'll set the production value to be "email-issues@fortelessons.com", but we want to be sure NEVER to set any other environment to send to that address.
    process.env.FORTE_ADMIN_EMAIL_ADDRESS_EMAIL_SEND_TRACKING ?? "",
  // We will likely add more varieties of ForteAdminEmailAddress here in the future.
} as const;
export type ForteAdminEmailAddress =
  (typeof forteAdminEmailAddress)[keyof typeof forteAdminEmailAddress];
// ------------------------------------------------------------------------

export const GIFTUP_API_KEY = process.env.GIFTUP_API_KEY;
