import { useState } from "react";

import { Button } from "flowbite-react";

import { Modal } from "@riffs/Modal";

export type PopConfirmationModalT = (arg: {
  onConfirm: () => void;
  onCancel?: () => void;
  title?: ConfirmationModalPropsT["title"];
  confirmText?: ConfirmationModalPropsT["confirmText"];
  cancelText?: ConfirmationModalPropsT["cancelText"];
  content: ConfirmationModalPropsT["content"];
}) => void;

export function useUpdateConfirmationModal() {
  const [modalProps, setModalProps] = useState<ConfirmationModalPropsT | null>(
    null,
  );

  const popConfirmationModal: PopConfirmationModalT = ({
    onConfirm,
    onCancel,
    content,
    confirmText,
    cancelText,
    title,
  }) => {
    setModalProps({
      content,
      showModal: true,
      title: title || "Confirm update",
      confirmText: confirmText || "Confirm",
      cancelText: cancelText || "Cancel",
      onConfirm: () => {
        onConfirm();
        setModalProps(null);
      },
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }
        setModalProps(null);
      },
    });
  };

  return {
    popConfirmationModal,
    confirmationModalProps: modalProps,
    ConfirmationModal,
  };
}

type ConfirmationModalPropsT = {
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  content: () => JSX.Element;
  title?: string;
  confirmText?: string;
  cancelText?: string;
};

function ConfirmationModal({
  onConfirm,
  onCancel,
  showModal,
  title,
  content,
  confirmText,
  cancelText,
}: ConfirmationModalPropsT) {
  return (
    <>
      {showModal && (
        <Modal onClose={() => onCancel()}>
          <Modal.Header
            onClose={() => {
              onCancel();
            }}
          >
            {title}
          </Modal.Header>
          <Modal.Body>{content()}</Modal.Body>
          <Modal.Footer>
            <div className="flex justify-between w-full">
              <Button color="dark" onClick={() => onCancel()}>
                {cancelText}
              </Button>
              <Button color="warning" onClick={() => onConfirm()}>
                {confirmText}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
