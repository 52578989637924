// DO NOT CHANGE THESE WILLY NILLY -- Emails, Partners, Client, and Server all need to agree
import { ParsedUrlQuery } from "node:querystring";

/*
 * wjustice: Note these FortePromoIds only formally "exist" here.
 * If we want to use these more broadly we should save them as enums in the DB and gql.
 */
export enum KnownFortePromoIds {
  SKOOVE_FLF = "skoove-flf", // Skoove "first lesson free"
  SWEETWATER_B3G1F = "sweetwater-b3g1f", // Sweetwater "Buy 3 get 1 free"
  SWEETWATER_FLF = "sweetwater-flf", // Sweetwater "first lesson free"
  TONESTRO_FLF = "tonestro-flf", // Tonestro "first lesson free"
  YOUSICIAN_FLF = "yousician-flf", // Yousician "first lesson free"
}

export enum KnownUtmQueryParams {
  utm_campaign = "utm_campaign",
  utm_content = "utm_content",
  utm_medium = "utm_medium",
  utm_source = "utm_source",
  utm_term = "utm_term",
}

/*
 * wjustice: Yes, these are formatted in all kinds of ways.
 * They were created at different times by different people.
 * But we need to support all of them because they're all out in the wild.
 */
export enum OnboardingQueryParams {
  CORRELATION_ID = "c_id",
  DISCOUNT_CODE = "discount_code",
  FORTE_PROMO_ID = "fpromo_id",
  INSTITUTION_FILTER = "institution_filter",
  INSTITUTION_IDS = "institutionIds",
  INSTRUMENT_ID = "instrumentId",
  MATCHING_REQUEST_ID = "matchingRequestId",
  ONBOARDING_TEST_ID = "onboardingTestId",
  ORIGIN_SLUG = "origin_slug",
  REFERRING_PARTNER_ID = "refid",
  SIGNUP_CODE = "signupCode",

  /**
   * allowing them to continue on the Survey+Browse, but they should be prevented from checking out
   * set when a new user answers the Age Question with a <13 years old;
   */
  IS_MINOR = "minor",

  /**
   * Used in conjunction with IS_MINOR=true, so that we can send the MinorTriedToScheduleTrialLesson email
   */
  PARENT_EMAIL = "parentEmail",

  /**
   * When a minor goes through the survey, we'll collect their parents' email
   * and send the parent an email which has instructions and might also
   * have the URL to browse their childs' TeacherResults, albeit with `?parent=true` instead of `?minor=true`.
   *
   * Until we support SignUpModeSelector in the AnonCheckout flow, we still dont want parents to be able to checkout from this AnonBrowseFlow,
   * but the "DisabledReason" should give them instructions on how to sign up as a parent instead of the Minors disabled reason
   * which said that they need to have their parent do this
   */
  IS_PARENT = "parent",
}

export function hasMinorQueryParam(queryParams: ParsedUrlQuery): boolean {
  const isMinor = queryParams[OnboardingQueryParams.IS_MINOR] === "true";
  return isMinor;
}

export function hasParentQueryParam(queryParams: ParsedUrlQuery): boolean {
  const isParent = queryParams[OnboardingQueryParams.IS_PARENT] === "true";
  return isParent;
}

export enum OnboardingTestId {
  // Survey -> Browse -> Register
  UNAUTHED_BROWSING = "unauthed_browsing",

  // Survey -> Register -> Browse
  USER_REGISTER = "user_register",
}

export enum KnownUtmSources {
  TEACHER_LINK = "teacher_link",
  PARENT_EMAIL = "parent_email",
}

export enum PersonalDetailsQueryParams {
  EMAIL = "email",
  FIRST_NAME = "first_name",
  // LAST_NAME = "last_name",
  // MOBILE_PHONE = "mobilephone",
}
