// This file must import nothing other than config. Keep it simple so that scripts like `server/emails/testEmailsWithFakeData.ts` can work.
import {
  NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_URL,
} from "../constants/config";
import { LearningHubQueryParam as LearningHubQueryParameter } from "../constants/matching";
import { TEACHER_PROFILE_BY_TEACHEROF_ID } from "./routes";
import { replaceTrailingSquareBracketsChunk } from "./stringHelper";

export const LOCALHOST = "localhost";
export const DOMAIN = NEXT_PUBLIC_VERCEL_URL
  ? NEXT_PUBLIC_VERCEL_URL
  : LOCALHOST;
const protocol = ["production", "preview"].includes(NEXT_PUBLIC_VERCEL_ENV)
  ? "https://"
  : "http://";
const DEFAULT_LOCALHOST_PORT = "3000";
export const BASE_URL = `${protocol}${
  NEXT_PUBLIC_VERCEL_URL ?? `${LOCALHOST}:${DEFAULT_LOCALHOST_PORT}`
}`;

const ADMIN = "/admin";
const STUDENT = "/student";
const TEACHER = "/teacher";
export const ANONYMOUS_BROWSE = `/browse`;

/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
export enum Routes {
  STUDENT_SCHEDULE = `${STUDENT}/schedule`,
  LEARNING_HUB = `${STUDENT}`,

  TEACHER_BILLING = `${TEACHER}/billing`,
  TEACHER_PROFILE = `${TEACHER}/profile`,
  TEACHER_SCHEDULE = `${TEACHER}/schedule`,
  TEACHER_STUDIO = `${TEACHER}/studio`,

  ADMIN_EMAILS = `${ADMIN}/emails`,
}
/* eslint-enable @typescript-eslint/prefer-literal-enum-member */

export const RouteQueryParams = {
  STUDENT_STUDIO: {
    REVIEW_TEACHER_ID: "reviewTeacherId",
  },
  LEARNING_HUB: {
    LEARNER_OF_ID: "learnerOfId",
  },
};

export const getStudentStudioPageForLearnerOf = (
  learnerOfId: null | string,
) => {
  if (!learnerOfId) {
    return Routes.LEARNING_HUB;
  }

  return `${Routes.LEARNING_HUB}?${LearningHubQueryParameter.ACTIVE_LEARNER_OF}=${learnerOfId}`;
};

export const generateTeacherResultsPathForAnonymousBrowsingMatchingRequestId = (
  matchingRequestId: string,
  queryParams?: Record<string, string>,
) => {
  const url = new URL(BASE_URL + ANONYMOUS_BROWSE + "/" + matchingRequestId);

  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value);
    }
  }

  return url.toString();
};

export const generateDirectTeacherUrl = (
  teacherOfId: string,
  queryParams?: Record<string, string>,
) => {
  const url = new URL(
    `${BASE_URL}${replaceTrailingSquareBracketsChunk(
      TEACHER_PROFILE_BY_TEACHEROF_ID,
      teacherOfId,
    )}`,
  );

  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value);
    }
  }

  return url.toString();
};
